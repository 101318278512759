.mt-150{ margin-top: 150px; }
.close2{ display: inline-block; border-radius: 60px; box-shadow: 0 0 2px #000; padding: 0.2em 0.3em; color: #000 !important; }
.close3{ display: inline-block; border-radius: 60px; box-shadow: 0 0 2px #000; padding: 5px 8px; color: #000 !important; font-size: 20px; }
.cartp2{ background: #efefef; padding: 50px 0; }
.coff{ text-decoration: none; color: #fff !important; background: #1f5407; padding: 1px 5px; text-decoration: none !important; }
.linet{ text-decoration: line-through; }
.cart_img1{ width: 100%;  }
.cart_img2{ width: 100% !important; padding-top: 7px;  }
.btn3,.btn3:hover{ background: #1f5407; color: #fff; padding: 10px 15px; border-radius: 7px; display: block; text-decoration: none !important; font-size: 12px; margin-top: 10px; }
.btn4,.btn4:hover{ background: #1f5407; color: #fff; padding: 10px 20px; border-radius: 7px; text-decoration: none !important; font-size: 14px; margin-top: 10px; }
.cartplus{ background: #fff !important; border: 1px solid #000; border-radius: 0; }
.cartminus{ background: #fff !important; border: 1px solid #000; border-radius: 0; }
.cart1{background: #fbcb2d; color: 000;}
.cartp p{ margin: 0px; font-size: 12px; }
.cartp2 p{ margin: 0px; font-size: 14px; }
.cartp h5{ font-size: 16px; margin: 0; }
.cartp h5 a{ text-decoration: none; color: #000; font-size: 12px; }
.mycart1{ padding-left: 12px; font-size: 16px; }
.pqtyt{ width: 50px !important; border: 1px solid #000 !important; border-radius: 0; }
.bdm{padding: 5px 0; }
.bd1{ border: 1px solid #ccc; background: #fff !important; }
.bd2{ border: 1px solid #ccc; padding:0px 15px 150px; background: #fff; min-height: 315px; }
.morder{ padding-left: 0; border-top: 1px solid #ccc; }
.morder li{ list-style: none;  }
.morder a{ text-decoration: none; color: #000; display: block; padding: 10px 15px; border-left: 3px solid transparent; }
.morder a:hover,.morder li.active a{ border-left:3px solid #1f5407; }
/*.cartp{ position: fixed !important; top: 10% !important; z-index: 9; width: 30%; }*/
.cartp{background: #fff; width: 39.4%; position: absolute; top: 62px !important; left: 17.8%; z-index: 9; display: none; }
.dpn1{ display: none !important; }
.qtybtn3{ font-size: 8px !important; }
.cartpc{background: #fff; box-shadow:0 0 10px #cbcbcb; width: 0%;
	 /* display: none; */
	  position: fixed; 
	  top: 0% !important; 
	  right: -5%;
	   z-index: 9; 
	  height: 100vh;
	  transition: all 300ms ease-out;
	}
.cartpc p{ margin: 0px !important; font-size: 12px; padding: 0 !important; line-height: 1.3;}	
.cartpc h5{ font-size: 16px; margin: 0; padding: 0; }
.cartpc h5 a{ text-decoration: none; color: #000; font-size: 12px; }
.gcircle_icon1{ height: 14px; width: auto; }
.cartp table td{ padding-left: 3px !important; padding-right: 3px !important; padding-top: 3px !important }
.close44{ float: right; margin-top: -5px; margin-right: 10px }
.navbutton1 {
	display: none;
    border: none !important;
    background: none !important;
    color: #1f8f07;
    font-size: 28px;
    padding: 0 !important;
    height: 28px;
    float: right;
}
.top43{
	top: 43px !important;
}
.Toastify__toast-body{
	font-size: 13px;
}
.loginbody{ background: #f5f5f5; font-size: 14px; }
.loginbox{ background: #fff; margin-top: 25px; padding: 30px;width:350px; margin-left: auto; margin-right: auto; margin-bottom: 60px; margin-top: 60px;}
.loginlogo{ height: 55px; width: auto; }
.loginbox h4{ text-align: center;  font-size:20px; margin-bottom: 20px }
.btn-green{
	background-color: #1f8e07;
	border: #1f8e07;
	color: #fff;
	outline: none;
}
.btn-green:hover{
	color: #fff;
}
.btn-green:focus{
	outline: none;
	box-shadow: none;
}
.loginbox .form-control{ height: 40px; border-radius: 3px; }
.ml-30{ margin-left: 30px; }
.font-small{ margin-bottom: 3px !important; font-size: 11px }
.sectionviv{ background: #fff; padding: 30px 0; }
.sectionviv h3{ color: #000; font-weight: 100 !important; font-size: 32px; }
.sectionviv h4{ color: #000; font-weight: 100 !important; font-size: 24px; }
.h3shead{ text-align: center; padding: 100px 0; }
.imgw{ width: 100%; }
.pr-0{ padding-right: 0; }
.pl-0{ padding-left: 0; }
.pd30{ padding: 30px 0; }
.slider{ padding: 100px; }
.back1{ background: url('../../../public/images/about/Logos-Wall2-1-1.jpg'), no-repeat; background-size: cover; background-attachment: fixed; padding: 0 !important; }
.bak1{ background: rgba(0, 0, 0, .3); padding: 100px 0; }
.btndiv{ background: url('../../../public/images/about/Our-team-collage-for-the-home.jpg') no-repeat; background-size: cover; background-attachment: fixed; }
.text-center{ text-align: center !important; }
.back1{ padding: 100px 0; }
.boxdiv{ padding: 100px 0; }
.btnl1{ border: 2px solid #47b475; padding: 10px 20px; margin-top: 20px; text-decoration: none; }
.boxdiv1{ padding: 25px; background: #f8f8f8; min-height: 250px; }
.btnl2{ border: 1px solid #fff; padding: 10px 20px;  color: #fff; }
.btndiv{ padding: 300px; }
.imgw2{ height: 500px; width: 100%; }
.hr5{ border: 1px solid #000 !important; background: #000 !important; }
.h1size1{ font-size: 48px; }
.divblack1{ background: rgba(0, 0, 0, .3); z-index: -9; padding: 25px; margin-top: 50px;  }


.video {
	position: relative;
	background-color: black;
	height: 100vh;
	min-height: 25rem;
	width: 100%;
	overflow: hidden;
  }
  
  .video video {
	position: absolute;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	z-index: 0;
	-ms-transform: translateX(-50%) translateY(-50%);
	-moz-transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
  }
  
  .video .container {
	position: relative;
	z-index: 2;
  }
  
  .video .overlay-wcs {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: black;
	opacity: 0.5;
	z-index: 1;
  }
  .video img{
	  width: auto !important;
  }
/*******Loading css********/
.loadingDefault-height{
	height: 100% !important;
}
.loadingDefault{
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 0px);
    background: #e5dfdf70;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

}
  /*****************/

  .categoryFtItem{
	  border-right:1px solid #c5c5c5;
	  padding: 0px 5px;
  }
  .categoryFtItem:nth-last-child(1){
	  border-right: 0px;
  }
  .modal-backdrop{
	  z-index: 1 !important;
  }
  .backBtn{
	color: #000;
    font-size: 16px;
    width: 30px;
    height: 30px;
    background: #f1f1f1;
    display: inline-block;
    text-align: center;
    line-height: 31px;
    margin-right: 20px;
    border-radius: 100%;
  }
  .cart-title{
	border-bottom: 1px solid #f7f0f0;
	padding: 14px 0px;
  }
  .address-section{
	  padding: 15px;
  }
  .choose-address{
	  padding: 0px 15px 15px;
	  /* overflow-y: scroll;
      height: 484px; */
  }
  .choose-address .address-title h5{
	font-size: 14px;
    margin-left: 4px;
  }
  .address-title{
	padding-bottom: 10px;
    padding-top: 5px;
  }
  .continue-btn-desabled{
	width: 100%;
    background: #efefef;
    text-align: center;
    position: absolute;
    bottom: 0;
    padding: 11px;
  }
  .continue-btn-desabled p{
	color: #00000070;
  }
  .addNewAdd a{
	width: 100%;
    text-align: left;
    border: none;
    padding: 9px 15px;
	border-radius: 5px;
	background-color: #efefef;
	color: #000 !important;
  }
  .continue-btn{
	width: 100%;
    background: #31af68;
    text-align: center;
    color: #fff;
    position: absolute;
    bottom: 0;
  }
  .continue-btn a {
    display: block;
    width: 100%;
    background: #1f5407;
    padding: 10px;
    color: #fff;
    text-decoration: none;
}
  .addNewAdd a i{
	  font-size: 14px;
	  margin-right: 10px;
	  color: #31af68;
  }
  #addressModal .modal-dialog{
	  max-width: 650px;
  }
  .showCart{
	display: block !important; 
	width: 35% !important;
	right: 0%;
}
.cartp table td{
	border-top: 1px solid #dee2e654 !important;
}
.cartpc table td{
	border-top: 1px solid #dee2e654 !important;
}
  .pt-30 {
    padding-top: 30px;
}
.dashboard-title{
	padding: 10px 0px;
    border-bottom: 1px solid #efefef;
}
.dashboard-title h4{
	margin: 0px;
}
  .user-heading{
	
		color: #31af68;
		font-weight: bold;
		margin: 17px 0 20px;
	
  }
  .border-bottom0{
	
		border-bottom: 1px solid transparent;
	
  }
  .set-default{
	display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  .set-default input{ margin-right: 5px;
    width: 17px;
    height: 17px;
    cursor: pointer;}
.text-darkgrey{
	color: #544747;
}

  .addressdiv {
    border: 1px solid #cbcdc8;
    border-radius: 3px;
    padding: 20px 10px 15px 20px;
	margin-bottom: 25px;
	position: relative;
	margin-top: 15px;
}
.choose-address .addressdiv{
	margin-top: 0px;
	margin-bottom: 15px;
}
.address-btn {
    background:#1f5407;
    margin-top: 4px;
    border: none !important;
    color: #fff !important;
    padding: 10px 20px;
    border-radius: 5px;
    display: inline-block;
    text-decoration: none !important;
}
.dashboard-content{
	padding-top: 5px;
}
.user-details-list{
	list-style: none;
	padding-left: 0px;
}
.user-details-list li{
	display: flex;
	align-items: center;
	padding: 5px 0px;
}
.user-details-list li .label{
	font-weight: 500;
	min-width: 170px;
}
.mh300{
	max-width: 323px;
	margin: 0 auto;
	padding: 20px;
}
.add-input {
    background: #e6e4e5;
    border-radius: 0;
	min-height: 45px;
}
.btn-address {
    background: #1f5407;
    color: #fff !important;
    padding: 7px 30px;
    border-radius: 5px;
    display: inline-block;
}
.btn-custom-primary {
    background: #1f8d06;
    color: #fff !important;
    padding: 5px 30px;
    border-radius: 5px;
    display: inline-block;
}
.pay-btn2{
	background: #1f5407 !important;
    border-color: #1f5407 !important;
    padding: 10px !important;
    color: #fff !important;
}
.actions{
	position: absolute;
    right: 5px;
    top: -16px;
    display: flex;
    align-items: center;
}
.actions-btn{
	
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    color: #fff;
    border-radius: 100%;
    
}
.remove-btn{
	background: #8a0e0e;
}
.edit-btn{
	background: #fbcb2d;
	margin-right: 5px;
}
.mh150{
	min-height: 150px !important;
}
.apply-coupon{
	display: none;
	opacity: 0;
}
.apply-coupon.show{
	display: block;
	opacity: 1;
}
.promocode-main{
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.promocode-main a{
width: 35px;
    height: 35px;
    display: block;
    text-align: center;
    line-height: 35px;
    cursor: pointer;
}
.head-center{
	display: block;
	text-align: center;
	width: 100%;
}
.coupon-box{
	background-color: #ffffff;
    padding: 10px;
	border-radius: 5px;
	margin-bottom: 10px;
}
.coupon-box ul{
	list-style: none;
	padding: 0;
}
.coupon-box ul li .couponItem{
  display: flex;
  align-items: center;
}
.coupon-box ul li .couponItem .coupon_code{
	padding: 10px;
    background-color: #fff;
    margin-right: 10px;
    border-radius: 5px;

}
.promo-code-error{
	background-color: #fff5f5;
    border-radius: 0 0 2px 2px;
    border: 1px solid #dbdbdb;
    border-top: 0;
    display: block;
    font-size: 14px;
    padding: 16px;
    line-height: normal;
	font-weight: 600;
	color: #868a8f;
}
.promo-code-error span{
	color: #a31414;
}

.order-box{
	border: 1px solid #efefef;
    padding: 20px;
}
.itemPrice{
	display: flex;
	justify-content: space-between;
}
.bdr{
	border-right:1px solid #efefef ;
}
.viewOrder {
	padding-top: 10px;
}
.viewOrder a{
	text-decoration: none;
    color: #000;
    font-size: 12px;
    background: #ebebeb;
    padding: 5px 10px;
    border-radius: 5px;	
}
.order-status{
	height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.order-status p{
	text-transform: uppercase;
	font-size: 12px;
}
.orderId{
	font-size: 12px;
}
.back-btn{
	text-decoration: none;
	color: #31af68;
    font-size: 13px;
}
.price-item{
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.searchSuggestion{
	display: block;
    width: 100%;
    left: 0;
    top: 14px !important;
	height: 350px;
    overflow-y: scroll;
}
/********/
.suggetion-table{
	margin-bottom: 0px;
	
}
.suggetion-table tr td{
	padding: 8px 5px !important;
	
}
.search-wrapper{
	position: relative;
}
.search-wrapper .searching{
	padding-left: 33px;
	min-height: 35px;
	font-size: 13px;
}
.search-wrapper i{
	position: absolute;
    top: 10px;
    left: 11px;
    color: #c3c3c3;
}
/*--- Checkout ---*/
.coupon_box{
	padding: 5px;
	background-color: #fff;
	border-radius: 5px;
}
.coupon_box .input-group-text{
	height: 40px !important;
    font-size: 14px !important;
    padding: 5px 25px !important;
    color: #8f8f8f;
    text-transform: capitalize;
	border: none;
	border-radius: 5px !important;
	cursor: pointer;
	transition: all 2ms ease;
}
.coupon_box .input-group-text.apply{
	background-color: #0c831f !important;
	color: #fff !important;
}
.coupon_box input{
	height: 40px !important;
	border: none;
}
.bg-light-grey{
	background-color: #e9ecef;
}
.mw-430{
	max-width: 430px;
}

.coupon_box input:focus{
	box-shadow: none;
	outline: none;
	border: none;
}

.promocode img{
	width: auto;
}
.chImg{
	max-width: 50px;
}
.checkoutsection{ padding: 50px 0 }
.checkoutsection h4{ font-size: 20px; }
.userinfo{ background: #fff; border:1px solid #cbcdc8; }
.phoneverification{ border-bottom: 1px solid #cbcdc8; padding:10px 25px; }
.paymentdiv{ border-bottom: 1px solid #cbcdc8; padding:10px 25px 40px; }
.phoneverification p{ margin-bottom: 0; font-size: 14px }
.text-grey{ color: #999 }
.checkout-btn{ background:#1f5407; margin-top: 4px; color: #fff !important; padding: 7px 20px; border-radius: 5px; display: inline-block; text-decoration: none !important;}
.btn-address{ background:#1f5407; color: #fff !important; padding: 7px 30px; border-radius: 5px; display: inline-block}
.checkoutnumber{ height: 26px; width: 26px; border: 1px solid #55b024; color:#55b024; padding-top: 2px; display: inline-block;border-radius: 100%; text-align: center; }
.total{ border: 1px solid #cbcdc8; padding: 25px 30px; margin-top: 30px; border-radius: 3px }
.total .pull-right{ float: right; }
.btop-dotted{ border-top: 1px dotted #cbcdc8; padding-top: 15px; margin-bottom: 0 }
.font12{ font-size: 12px }
.promocode{ border: 1px solid #cbcdc8; margin:20px 0; padding: 25px 30px;border-radius: 3px  }
.promocode p{ margin-bottom: 0 }
.promoimg{ margin-right: 25px; }
.font18{ font-size: 22px; }
.revieworder{ border:1px solid #cbcdc8; background: #fff}
.revieworder h4{ padding: 15px 25px; margin:0; }
.table-revieworder{ font-size: 14px }
.table-revieworder td{ }
.table-revieworder td p{ margin-bottom: 0; line-height: 1.1 }
.checkouttabs nav a{ position:relative;border-top: 1px solid #cbcdc8 !important;border-left: 1px solid #ccc !important;border-bottom: 1px solid #ccc !important; border-radius: 0 !important; color: #888 !important;}
.checkouttabs nav a:last-child{ border-right: 1px solid #cbcdc8; }
.checkouttabs nav a.active{ background: #0c831f !important; color: #fff !important; border:1px solid #0c831f !important; }
.checkouttabs nav a.active:after{content: '';width: 10px;height: 10px;background: #fff;position: absolute;transform: rotate(45deg);top: 33px;left:44%;}
.checkouttabs .tab-pane{padding: 32px 0;}
.pay-btn{ background:#898989 !important; border-color:#898989 !important; padding: 10px !important  }
.pay-btn.active{
	background:#1f5407 !important;
}
.checkouttabs .form-control{ height: 45px; }
.verify{ position: absolute;
    top: 39px;
    right: 25px;
    background: transparent;
    border: none;
    font-size: 14px;}
.payment-error{
	color: #af0b0b;
    font-size: 13px;
}
#payment-form iframe{
	min-height: 414px !important;
	overflow: hidden;
} 
/* width */
#payment-form iframe::-webkit-scrollbar {
	width: 10px;
  }
  
  /* Track */
  #payment-form iframe::-webkit-scrollbar-track {
	background: #f1f1f1;
  }
  
  /* Handle */
  #payment-form iframe::-webkit-scrollbar-thumb {
	background: #888;
  }
  
  /* Handle on hover */
  #payment-form iframe::-webkit-scrollbar-thumb:hover {
	background: #555;
  }
#payment-form iframe .pay-body{
	padding: 0px !important;
}
#cashfreeModal .modal-header, #cashfreeModal .close span{
	background: #31af68;
    color: #fff;
	opacity: 1;
    text-shadow: none;
}
.mr-30{ margin-right: 50px; }
#addressModal .modal-header, #updateAddressModal .modal-header{ border: none ; padding: 10px !important }
#addressModal .modal-body{ border: none ; padding:0 30px 40px !important }
#addressModal h5, #updateAddressModal h5{ color: #1f5407;
    text-transform: capitalize;
    font-weight: 700; font-size: 22px;}
	#updateAddressModal .modal-body{  padding:0px 30px 30px !important }
.close span{
	font-size: 25px;
}
.morder li a{

}
.morder li a i{
	color: #31af68;
	font-size: 18px;
	margin-right: 7px;
}
.qty_add_box{
	max-width: 120px;
}
.qty_add_box .input-group-text{
	font-size: 13px;
    padding: 5px 10px !important;
    font-weight: 700;
    height: 36px !important;
}
.qty_add_box input{
	text-align: center;
}
.addressdiv p{ margin-bottom: 5px; font-size: 14px }
.add-input{ background: #e6e4e5;border-radius: 0 }
.checkouttabs .tab-content{ border-bottom: 1px solid #cbcdc8; border-left: 1px solid #cbcdc8; border-right: 1px solid #cbcdc8; }
.border-bottom0{ border-bottom: 1px solid transparent; }
.user_type label{
	margin-right: 15px;
    display: flex;
    align-items: center;
}
.user_type label input{
	margin-right: 3px;
}
/*****Back Css******/
.bank-item-wrap{
	width: 100%;

}
.bank_list{
display: block;
position: relative;
}
/* .bank-item{
	display: inline-block;
    min-height: 50px;
    background-color: red;
    margin: 10px;
    max-width: 94px;
} */
.bank-item-wrap{
	display: flex;
	align-items: center;
	width: 100%;
    padding: 12px 12px 12px 0px;

}
.bank_icon{
	max-width: 50px;
    max-height: 50px;
    margin-right: 10px;
    margin-left: 10px;
}

.bank_icon img{
	width: 100%;
	
}
.bank-item-wrap span{
	font-size: 12px;
}
.bank-item-wrap label{
	display: flex;
	align-items: center;
	width: 100%;
	cursor: pointer;
}
.bank-item{
	border-bottom: 1px solid rgb(0 0 0 / 2%);
}
.net-banking-model-btn {
    width: 100%;
    text-align: left;
    background: transparent;
    color: #000;
    font-size: 14px;
    display: flex;
    border: 1px solid #ced4da;
    min-height: 45px;
    align-items: center;
    justify-content: space-between;
}
.net-banking-model-btn .fa {
	font-size: 10px;
    color: #605d5d;

}
.mh-375{
	max-width: 375px;
}
.net-banking-banks-body{
	max-height: 400px;
    overflow-y: scroll;
	padding: 0;
}
.net-banking-banks-body ul{
  list-style: none;
  padding-left: 0px;
}
.net-banking-banks-body ul li{
	padding: 7px 15px;
	cursor: pointer;
	transition: all 200ms ease-in-out;
	font-size: 14px;
}
.net-banking-banks-body ul li:hover, .net-banking-banks-body ul li.active{
	background-color: #f9f8f8;
}
/****Custom modal*****/
.mxw-950{
	max-width: 950px !important;
}
.modelLocation{
	top: 50px !important;
}
.user-location{
	position: relative;
}

.user-location .modal-content:before {
	content: '';
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 10px solid #fff;
display: inline-block;
vertical-align: middle;
position: absolute;
top: -10px;
left: 0;
right: 0;
margin-left: 30px;

}
.address_locator{
	position: absolute;
    background: #fff;
    padding: 20px;
    min-width: 450px;
    min-height: 221px;
    z-index: 1000;
}
.address_locator::backdrop {
	background: rgba(255,0,0,.25);
  }
/****order product********/
.product-item{
	
}
.input-group-text{
	padding: 3px !important;
	height: 26px !important;
	font-size: 12px;
}
.product-item .input-group input{
	padding: 3px !important;
	height: 26px !important;
	text-align: center;
}
.wallet-btn{
	max-width: 180px;
    font-size: 12px;
    padding: 7px !important;
	background: #1f5407;
}
.wallet-btn img{
	height: 22px;
    width: auto;
    margin-right: 5px;
}

.owo-wallet{
	text-align: center;
    padding: 19px;
    border: 1px solid #cbcdc8;
    margin-bottom: 13px;
}
.pay-with-wallet{
	display: flex;
    align-items: center;
    justify-content: space-between;
}
.order-product-item{
	display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #efefef;
    padding: 5px;
}
.order-product-item:nth-last-child(1){
	border-bottom:0px;
}
.prod_details{
	display: flex;
}
.prod_thumb{
	max-width: 70px;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}



.contact-wrapper{
	padding: 50px 0px;
}
.contact-wrapper .btn-contact{
	display: block;
    width: 100%;
    background: #1f5407;
    padding: 10px;
    color: #fff;
    text-decoration: none;
    border: none;
    box-shadow: none;
    max-width: 200px;
    border-radius: 5px;
}

.contact-wrapper .text-bold{
	color: #000;
    font-size: 19px;
	margin-bottom: 10px;
    margin-bottom: 5px;
}
#payment_method{
	font-size: 18px;
	margin-bottom: 10px;
}
.payM{
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.payM label{
	display: flex;
	align-items: center;
}
.resp-box{
	background: #efefef;
    max-width: 450px;
    margin: 50px auto;
    text-align: center;
    padding: 42px;
    border-radius: 10px;
}
.resp-box h3{
  font-size: 22px;
  margin-top: 5px;
  color: rgb(97, 13, 13);
}
.resp-box p{

	margin-bottom: 15px;
}
.resp-box i{
	font-size: 30px;
	color: rgb(97, 13, 13);
}
.payWithpayU{
	display: flex;
    align-items: center;
    border: none;
    padding: 10px 25px;
    text-transform: capitalize;
    font-size: 14px;
    max-width: 250px;
    margin: 27px auto;
}
.payWithpayU img{
	max-width: 98px;
}

.carousel-button-group{
	position:absolute;
	right:20px;
	top:-32px;
}
.carousel-button-group button{
	background-color: transparent;
	border: none;
}
@media only screen and (max-width: 767px) {

		.modelLocation {
			top: 133px !important;
		}
	.cartp table td label{ display: none !important; }
	.navbutton1{display: block;}
	.loginbox{ width: 100%; }
	.pqtyt{ height: 25px !important; margin:0 !important; }
	.cartp table td{ padding-left: 1px !important; padding-right: 1px !important; padding-top: 3px !important }
	.cartp{background: #fff; width: 92%; position: absolute; top: 17% !important; left: 4%; z-index: 9; }
	.cartp h5 a{ font-size: 10px; line-height: 1 }
	.cartp h5{ line-height:.5; }
	.cartp p{ font-size: 10px; line-height: 1; }
	.cpit{ padding: 5px !important; width: 15%; }
	.cpft{ padding-top: 0 !important; }
	.cpl{ padding-left: 0 !important; padding-right: 0 !important; }
	.cpq{ padding-top: 5px !important; }
	.cpp{padding-left: 0 !important; padding-right: 0 !important;}
	.cpab{ margin-top: 0 !important;min-width: 42px; padding-left: 0 !important; padding-right: 0 !important }

	/*.cartpc{background: #fff; width: 50% !important; position: absolute; top: 0% !important; right: 0%; z-index: 9; height: 100vh; }*/
	.close2{ padding: 0.1em 0.2em; }
	.close3{ padding: 1px 3px; }
	.c1{ padding-left: 0 !important; padding-right: 0 !important; }
	.mycart1{ padding-left: 35px; }
	.cartpc h5 a{ font-size: 6px; }
	.cartpc h5{ line-height: .5; }
	.cartpc p{ font-size: 6px; line-height: 1.5; }
	.cpci{ padding: 0 !important; width: 10%; }
	.cpcf{ padding-top: 5px !important; width: 46% !important;  }
	.cpcp{ padding-right: 0 !important; padding-left: 0; }
	.fa-plus2{ font-size: 6px !important; }
	.fa-minus2{ font-size: 6px !important; }
	.cpcp{ padding-right: 0 !important; padding-left: 0 !important; }
	.close2{ padding: 0.1em 0.2em; }
	.close3{ padding: 1px 3px; }
	.cart_img2{ padding-top: 10px; }
	.btn3{ font-size: 7px; padding: 10px 12px; }
	.video{ height: 50vh !important; }
	.video h4{ font-size: 14px; }
	.sectionviv h3{ font-size: 28px; }
	.sectionviv{ padding: 0; }
	.imgw2{ height: auto; width: 95%; margin-bottom: 30px; }
	.back1{ margin-top: 50px; }
	.h1size1 { font-size: 40px; }


}

@media (min-width: 768px) and (max-width: 1024px){
	
	.cartp{background: #fff; width: 37%; position: absolute; top: 5.4% !important; left: 19%; z-index: 9; }
	.cpit{ width: 20%; padding: 5px !important; }
	.cpft{ padding-top: 0 !important; width: 46%; }
	.cartp h5 a { font-size: 6px !important; }
	.cpab{ font-size: 6px !important; margin-top: 0 !important; }
	.cartp p { font-size: 6px; line-height: 2;}
	.cpp{ padding-right: 0 !important; }
	.pqtyt{ width: 50px !important; }
	.cpq{ width: 22% !important; padding-top: 2px !important; }
	.cpl{ padding-right: 0 !important; padding-left: 0 !important; width: 10% !important; }
	.cartp h5{ line-height: 1; }
	.cpa{ padding: 3px !important; }
	.c1{ padding-left: 0 !important; padding-right: 0 !important; }
	.mycart1{ padding-left: 17px; }
	.cartpc h5 a{ font-size: 6px; }
	.cartpc h5{ line-height: .5; }
	.cartpc p{ font-size: 6px; line-height: 1.5; }
	.cpci{ padding: 0 !important; width: 10%; }
	.cpcf{ padding-top: 5px !important; width: 46% !important;  }
	.cpcp{ padding-right: 0 !important; padding-left: 0; }
	.fa-plus2{ font-size: 6px !important; }
	.fa-minus2{ font-size: 6px !important; }
	.cpcp{ padding-right: 0 !important; padding-left: 0 !important; }
	.close2{ padding: 0.1em 0.2em; }
	.close3{ padding: 1px 3px; }
	.cart_img2{ padding-top: 10px; }
	.btn3{ font-size: 7px; padding: 10px 12px; }
	.sidebar1{ padding: 10px 0 !important; }
	#accordion button{ font-size: 12px !important; padding: 0 5px }
	#accordion i{ margin-top: 5px !important; margin-right: 5px !important; }


.cartp {
    background: #fff;
    width: 96%;
    position: absolute;
    top: 12% !important;
    left: 2%;
    z-index: 9;
}
}